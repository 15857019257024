var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentPageType !== null
    ? _c(
        "div",
        [
          _vm.currentPageType === "folder" || _vm.currentPageType === "project"
            ? _c("folder-and-project-breadcrumb", {
                attrs: {
                  "items-in-breadcrumb":
                    _vm.currentItem && _vm.currentItem.breadcrumbs
                      ? _vm.currentItem.breadcrumbs
                      : [],
                  "current-page-type": _vm.currentPageType,
                  "is-group-user": _vm.userObject.isGroupUser,
                  "team-access-to-root-folder": _vm.teamAccessToRootFolder,
                  "access-to-root-folder-settings":
                    _vm.accessToRootFolderSettings,
                  "access-to-folder-settings": _vm.accessToFolderSettings,
                  "access-to-project-settings": _vm.accessToProjectSettings
                }
              })
            : _vm.currentPageType === "page"
            ? _c("page-breadcrumb", {
                key: "page-bread",
                attrs: {
                  "page-item": _vm.lastItemOfBreadcrumb,
                  "access-to-page-settings": _vm.accessToPageSettings,
                  "folder-or-project-item": _vm.penultimateItemOfBreadcrumb
                }
              })
            : _vm.isSettingsPage
            ? _c("settings-breadcrumb", {
                attrs: {
                  item: _vm.lastItemOfBreadcrumb,
                  "parent-item-of-page": _vm.parentItemOfPage,
                  "current-page-type": _vm.currentPageType
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }