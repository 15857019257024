<template>
  <div v-if="currentPageType !== null">
    <folder-and-project-breadcrumb
      v-if="currentPageType === 'folder' || currentPageType === 'project'"
      :items-in-breadcrumb="(currentItem && currentItem.breadcrumbs) ? currentItem.breadcrumbs : []"
      :current-page-type="currentPageType"
      :is-group-user="userObject.isGroupUser"
      :team-access-to-root-folder="teamAccessToRootFolder"
      :access-to-root-folder-settings="accessToRootFolderSettings"
      :access-to-folder-settings="accessToFolderSettings"
      :access-to-project-settings="accessToProjectSettings"
    />
    <page-breadcrumb
      v-else-if="currentPageType === 'page'"
      :page-item="lastItemOfBreadcrumb"
      :access-to-page-settings="accessToPageSettings"
      :folder-or-project-item="penultimateItemOfBreadcrumb"
      key="page-bread"
    />
    <settings-breadcrumb
      v-else-if="isSettingsPage"
      :item="lastItemOfBreadcrumb"
      :parent-item-of-page="parentItemOfPage"
      :current-page-type="currentPageType"
    />
  </div>
</template>

<script>
export default {
  name: 'HeaderBreadcrumb',
  components: {
    FolderAndProjectBreadcrumb: () => import('@/components/header/breadcrumb/FolderAndProjectBreadcrumb.vue'),
    SettingsBreadcrumb: () => import('@/components/header/breadcrumb/SettingsBreadcrumb.vue'),
    PageBreadcrumb: () => import('@/components/header/breadcrumb/PageBreadcrumb.vue'),
  },
  props: {
    userObject: {
      type: Object,
      required: true,
    },
    currentItem: {
      type: Object,
      default: null,
    },
    groupId: {
      type: Number,
    },
    allItemPathOfTeamsItems: {
      type: Array,
    },
    teamAccessToRootFolder: {
      type: Boolean,
      default: false,
    },
    currentPageType: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    settingsPages: [
      'folder-settings',
      'project-settings',
      'page-settings',
      'general-settings',
      'group-settings',
      'team-settings',
      'personalization-settings',
      'reports-settings',
      'form-settings',
    ],
  }),
  computed: {
    accessToRootFolderSettings() {
      if (this.userObject.isSysAdmin
        && this.currentPageType === 'folder'
        && this.isRootFolder) {
        return true;
      }
      if (!this.currentItem) {
        return false;
      }
      return !!(this.currentPageType === 'folder'
        && this.isRootFolder
        && (this.userObject.isGroupUser || this.teamAccessToRootFolder)
        && this.$hasPrivileges(
          this.$privileges('CAN_EDIT_ROOT_SETTINGS'),
          this.userObject.isSysAdmin,
          this.currentItem.privilegesForItem,
        ));
    },
    accessToFolderSettings() {
      if (this.userObject.isSysAdmin && this.currentPageType === 'folder') {
        return true;
      }
      if (!this.currentItem) {
        return false;
      }
      return !!(this.currentPageType === 'folder'
        && !this.isRootFolder
        && this.$hasPrivileges(
          this.$privileges('CAN_EDIT_FOLDER_SETTINGS'),
          this.userObject.isSysAdmin,
          this.currentItem.privilegesForItem,
        ));
    },
    accessToProjectSettings() {
      if (this.userObject.isSysAdmin && this.currentPageType === 'project') {
        return true;
      }
      if (!this.currentItem) {
        return false;
      }
      return !!(this.currentPageType === 'project'
        && this.$hasPrivileges(
          this.$privileges('CAN_EDIT_PROJECT_SETTINGS'),
          this.userObject.isSysAdmin,
          this.currentItem.privilegesForItem,
        ));
    },
    accessToPageSettings() {
      if (this.userObject.isSysAdmin && this.currentPageType === 'page') {
        return true;
      }
      return !!(this.currentPageType === 'page'
        && this.$hasPrivileges(
          this.$privileges('CAN_EDIT_PAGE_SETTINGS'),
          this.userObject.isSysAdmin,
          this.currentItem.privilegesForItem,
        ));
    },
    isRootFolder() {
      return !this.$route.params.parametersOfUrl;
    },
    isFolderOrProject() {
      if (this.currentPageType) {
        return this.currentPageType === 'folder'
          || this.currentPageType === 'project';
      }
      return false;
    },
    isPage() {
      if (this.currentPageType) {
        return this.currentPageType === 'page';
      }
      return false;
    },
    isSettingsPage() {
      if (this.currentPageType) {
        return this.settingsPages.includes(this.currentPageType);
      }
      return false;
    },
    lastItemOfBreadcrumb() {
      if (this.currentPageType && this.currentItem && this.currentItem.breadcrumbs?.length) {
        return this.currentItem.breadcrumbs[this.currentItem.breadcrumbs.length - 1];
      }
      return null;
    },
    parentItemOfPage() {
      if (this.currentPageType === 'page-settings' && this.currentItem.breadcrumbs?.length > 1) {
        return this.currentItem.breadcrumbs[this.currentItem.breadcrumbs.length - 2];
      }
      return null;
    },
    penultimateItemOfBreadcrumb() {
      if (this.currentPageType && this.currentItem.breadcrumbs?.length > 1) {
        return this.currentItem.breadcrumbs[this.currentItem.breadcrumbs.length - 2];
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.breadcrumb {
  background-color: transparent;

  .breadcrumb-item {
    display: flex;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 16px;

    &::before {
      padding-top: 4px;
      padding-right: 16px;
    }
  }
}

h2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #fff;
}

a {
  color: #777C7F;

  h2 {
    color: #777C7F;
  }
}

a:hover {
  text-decoration: none;
  color: #53DC86;

  h2 {
    color: #53DC86;
  }
}
</style>
